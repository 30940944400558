<template>
    <div class="custom-mobile-view">
      <div class="custom-container bg-white">
        <TopNav :page="stage" :signOutIcon="true"></TopNav>
  
        <div class="p-4 custom-mb-20 custom-scroll-container" v-if="profile != null">
            <div class="custom-light-blue-bg p-4"> 
                <div class="d-flex justify-content-center">
                    <div class="custom-initials-container">
                        <label class="text-white text-center">{{ getInitials(profile.employeeName) }}</label>
                    </div>
                </div>

                <div class="d-flex justify-content-center mt-2">
                    <small class="text-white text-center">{{ profile.employeeName }}</small>
                </div>               
            </div>

            <div class="mt-4">
                <b-row>
                    <b-col lg="6" md="6" sm="6">
                        <p class="text-muted mb-0">
                            Payroll Number
                        </p>
                        <p class="fw-bold">
                            {{ profile.payrollNumber }}
                        </p>
                    </b-col>

                    <b-col lg="6" md="6" sm="6">
                        <div class="custom-stacked-section">
                            <p class="text-muted mb-0">
                                ID Card Number
                            </p>
                            <p class="fw-bold">
                                {{ profile.idCardNumber }}
                            </p>
                        </div>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col lg="6" md="6" sm="6">
                        <div class="custom-stacked-section">
                            <p class="text-muted mb-0">
                                Email
                            </p>
                            <p class="fw-bold">
                                {{ profile.emailAddress }}
                            </p>
                        </div>
                        
                    </b-col>

                    <b-col lg="6" md="6" sm="6">
                        <div class="custom-stacked-section">
                            <p class="text-muted mb-0">
                                Phone Number
                            </p>
                            <p class="fw-bold">
                                {{ profile.phoneNumber }}
                            </p>
                        </div>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col lg="6" md="6" sm="6">
                        <p class="text-muted mb-0">
                            National ID No.
                        </p>
                        <p class="fw-bold">
                            {{ profile.nationalIdNumber }}
                        </p>
                    </b-col>

                    <b-col lg="6" md="6" sm="6">
                        <div class="custom-stacked-section">
                            <p class="text-muted mb-0">
                                KRA PIN
                            </p>
                            <p class="fw-bold">
                                {{ profile.taxNumber }}
                            </p>
                        </div>
                    </b-col>
                </b-row>
                
            </div>
        </div>
  
        <BottomMenu :menu="stage"></BottomMenu>
      </div>
    </div>
  </template>
  
  <script>
  import { mapMutations } from 'vuex'
  import api from '@/apis/api'
  import TopNav from '@/components/TopNav.vue'
  import BottomMenu from '@/components/BottomMenu.vue'
  
  export default {
    name: 'HomeView',
    components: {
      TopNav,
      BottomMenu
    },
  
    data() {
      return {
        stage: "Profile",
        profile: null,
      }
    },
  
    created() {
      this.getCustomerDetails()
    },
  
    methods: {
        getCustomerDetails() {
            let that = this

            api.info()
                .then((response) => {
                    that.profile = response.data
                })
                .catch((error) => {
                    that.showError(error)
                })
        },

        getInitials(fullName) {
            const nameParts = fullName.split(' ');
            const firstNameInitial = nameParts[0] ? nameParts[0][0] : '';
            const lastNameInitial = nameParts[1] ? nameParts[1][0] : '';

            return firstNameInitial + lastNameInitial;
        }
    }
}
  </script>
  