import axios from 'axios'
import { config } from '@/util/config.js'

let APIM = function() {
  let baseAPI  = axios.create({
    baseURL: config.keycloackURL,
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': 'Basic c3luYzo=',
    }
  })

  baseAPI.interceptors.response.use(
    response => response,
    error => {
      //
    }
  )

  return baseAPI
}

export default APIM; 