<template>
  <div class="custom-mobile-view">
    <div class="custom-container bg-white ">
      <TopNav :path="back"></TopNav>

      <div class="px-4" v-if="!submitted">
        <div class="custom-mt-12 ">
          <h5 class="custom-text-orange text-center fw-bold">Access your pay anyday.</h5>
          <p class="text-muted text-center">Provide us with the details below to recommend us to your employer.</p>
        </div>

        <div class="custom-mt-8">
          <b-card header="Employer details">
            <div>
              <p class="text-muted text-center">Employer Name</p>

              <div class="d-flex justify-content-center">
                <b-form-input placeholder="Enter employer's name" class="custom-input-border-bottom" type="text"
                  v-model="organisation"></b-form-input>
              </div>
            </div>

            <div class="mt-4">
              <p class="text-muted text-center">Employer Email</p>

              <div class="d-flex justify-content-center">
                <b-form-input placeholder="Enter employer's email" class="custom-input-border-bottom" type="text"
                  v-model="employerEmailAddress"></b-form-input>
              </div>
            </div>
          </b-card>

          <b-card class="mt-4" header="Your details">
            <div class="mt-4">
              <p class="text-muted text-center">Full Names</p>

              <div class="d-flex justify-content-center">
                <b-form-input placeholder="Enter your full names" class="custom-input-border-bottom" type="text"
                  v-model="employeeName"></b-form-input>
              </div>
            </div>

            <div class="mt-4">
              <p class="text-muted text-center">National ID Number</p>

              <div class="d-flex justify-content-center">
                <b-form-input placeholder="Enter your ID number" class="custom-input-border-bottom" type="number"
                  v-model="nationalIdNumber"></b-form-input>
              </div>
            </div>
          </b-card>
          
          <div class="custom-mt-12 d-flex justify-content-center">
            <b-button class="custom-orange-button" @click="submitLead()" :disabled="submitBtn">Submit</b-button>
          </div>

          <div class="custom-mt-12 d-flex justify-content-center">
            <img src="@/assets/img/logo.png" width="150px" />
          </div>

          <div class="py-4 d-flex justify-content-center">
            <small class="text-muted text-center">Powered by Sipranda Capital Ltd.</small>
          </div>

        </div>
      </div>

      <div class="p-4 custom-mb-20 custom-scroll-container" v-if="submitted">
          <div>
            <p class="custom-text-blue text-center fw-bold">
                Your request has been submitted successfully.                 
            </p>

            <div class="custom-card-orange custom-mt-12">
                <p class="text-muted text-center p-4">
                    With Jilipe Sasa service, you can access up to 50% of your earned pay, 
                    allowing you to better match costs across the month. Its payday anyday.
                </p>

                <div class="custom-card-orange-footer"></div>
            </div>
          </div>
        </div>
    </div>
  </div>
</template>
  
<script>
import { mapMutations, mapGetters } from 'vuex'
import TopNav from '@/components/TopNav.vue'
import BottomMenu from '@/components/BottomMenu.vue'
import api from '@/apis/api'

export default {
  name: 'ResetPin',
  components: {
    TopNav,
    BottomMenu
  },

  data() {
    return {
      back: "/login/step-one",
      submitBtn: false,
      organisation: null,
      employeeName: null,
      nationalIdNumber: null,
      employerEmailAddress: null,
      submitted: false
    }
  },

  methods: {
    submitLead() {
      if (this.organisation != null && this.employeeName != null && this.nationalIdNumber != null && this.employerEmailAddress != null) {

        let that = this

        let data = {}

        data.organisation = this.organisation
        data.employeeName = this.employeeName
        data.employerEmailAddress = this.employerEmailAddress
        data.nationalIdNumber = this.nationalIdNumber

        api.createLead(data)
          .then((response) => {
            that.submitted = true
            that.showSuccess("Your details were submitted successfully.")
          })
          .catch((error) => {
            that.showError(error)
          })
      }
      else {
        this.showError("Details cannot be empty")
      }
    }
  }
}
</script>
  