<template>
    <div class="custom-mobile-view">
      <div class="custom-container bg-white">
        <TopNav page="Transact" :signOutIcon="true"></TopNav>
  
        <div class="p-4 custom-mb-20 custom-scroll-container" v-if="advanceDetails != null">
          <div>
            <p class="custom-text-blue text-center fw-bold">
                Your request has been submitted successfully.
            </p>

            <p class="custom-text-blue text-center fw-bold">
                Funds shall be deposited to the following Mpesa number:
            </p>

            <h4 class="custom-text-green text-center mt-4">{{ advanceDetails.msisdn }}</h4>

            <div class="custom-card-orange custom-mt-12">
                <p class="text-muted text-center p-4">
                    With Jilipe Sasa service, you can access up to {{ getJilipePercentage }}% of your earned pay, 
                    allowing you to better match costs across the month. Its payday anyday.
                </p>

                <div class="custom-card-orange-footer"></div>
            </div>
          </div>
        </div>
  
        <BottomMenu :menu="stage"></BottomMenu>
      </div>
    </div>
  </template>
  
  <script>
  import { mapGetters } from 'vuex'
  import TopNav from '@/components/TopNav.vue'
  import BottomMenu from '@/components/BottomMenu.vue'
  
  export default {
    name: 'RequestSuccessView',
    components: {
      TopNav,
      BottomMenu
    },
  
    data() {
      return {
        stage: "Home",
        advanceDetails: null,
      }
    },

    computed: {
      ...mapGetters(["getAdvanceDetails", "getJilipePercentage"])
    },

    created() {
      if(this.getAdvanceDetails != null) {
        this.advanceDetails = this.getAdvanceDetails
      }
      else {
        this.navigate("/home")
      }
    }
  }
  </script>
  