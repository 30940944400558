<template>
    <div class="custom-mobile-view">
      <div class="custom-container bg-white">
        <TopNav></TopNav>
  
        <div class="custom-mt-12">
          <h5 class="text-muted text-center fw-bold">Welcome to Jilipe Sasa</h5>
        </div>
  
        <div class="custom-mt-12">
          <div class="mt-4">
            <p class="text-muted text-center">Enter your mobile number to login</p>
          </div>     
  
          <div class="d-flex justify-content-center">
            <b-form-input placeholder="Enter phone number" class="custom-input-border-bottom" type="number" v-model="phoneNumber"></b-form-input>
          </div>     
  
          <div class="custom-mt-12 d-flex justify-content-center">
            <b-button class="custom-orange-button" @click="submit()" :disabled="submitBtn">Continue</b-button>
          </div>

          <div class="px-4 mt-4" v-if="allowRegistration">
              <b-alert class="text-center" variant="danger" show>Phone number could not be found. 
                <span class="fw-bold text-decoration-underline custom-pointer" @click="navigate('/recommend')">Register here </span> 
                if you would like to recommend your employer to join Jilipe Sasa.</b-alert>
          </div>
  
          <div class="custom-mt-12 d-flex justify-content-center">
            <img src="@/assets/img/logo.png"  width="150px"/>
          </div>
  
          <div class="mt-4 d-flex justify-content-center">
            <small class="text-muted text-center">Powered by Sipranda Capital Ltd.</small>
          </div>    
          
        </div>
      </div>
    </div>  
  </template>
  
  <script>
  import api from "@/apis/api"
  import { mapMutations } from 'vuex'
  import TopNav from '@/components/TopNav.vue'
  import BottomMenu from '@/components/BottomMenu.vue'
  
  export default {
    name: 'LoginPhone',
    components: {
      TopNav,
      BottomMenu
    },
  
    data() {
      return {
        back: "/login/step-two",
        stage: "Auth",
        phoneNumber: null,
        submitBtn: false,
        allowRegistration: false,
      }
    },

    created() {
      localStorage.clear()
    },

    methods: {
      ...mapMutations([
        "setPhoneNumber"
      ]),

      submit() {
        if(this.phoneNumber != null) {

          let that = this
          this.submitBtn = true

          api.verifyPhone(this.phoneNumber)
            .then((response) => {
              that.setPhoneNumber(this.phoneNumber)
              that.navigate('/login/step-two')

              that.submitBtn = false
            })
            .catch((error) => {
              that.submitBtn = false
              let errorCode = error.response.status

              if(errorCode == 404) {
                that.showError("Phone number could not be found!")
                that.allowRegistration = true
              }
              else {
                that.showError(error)
              }             
            })          
        }
        else {
          this.showError("Phone number cannot be empty")
        }        
      }
    }
  }
  </script>
  