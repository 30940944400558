<template>
  <div class="custom-mobile-view">
    <div class="custom-container bg-white">
      <TopNav :path="back" :signOutIcon="true"></TopNav>

      <div class="p-4 custom-mb-20 custom-scroll-container ">
        <div>
          <p class="custom-text-blue text-center fw-bold">Kindly review your Jilipe Sasa service request below.</p>
        </div>

        <div class="custom-blue-card mt-4">
          <div class="p-2">
            <p class="text-center text-muted mb-2">Amount requested</p>
            <h4 class="text-center">KES {{ quoteDetails.drawDown.toLocaleString() }}</h4>
          </div>          

          <div class="custom-gray-card">
            <div class="py-2 px-4">
              <div class="d-flex justify-content-between">
                <small class="text-white custom-width">{{ quoteDetails.processionFeeDisplayName }}</small>
                <small class="text-white">KES: {{ quoteDetails.processingFee.toLocaleString() }}</small>
              </div><hr />

              <div class="d-flex justify-content-between">
                <small class="text-white custom-width">{{ quoteDetails.accessFeeDisplayName }}</small>
                <small class="text-white">KES: {{ quoteDetails.accessFee.toLocaleString() }}</small>
              </div>            
            </div>
          </div>
          

          <div class="my-2">
            <p class="text-center text-muted mb-2">You will receive</p>
            <h4 class="text-center">KES {{ quoteDetails.total.toLocaleString() }}</h4>
          </div>
        </div>

        <b-button class="custom-block-button" @click="submit()" block :disabled="submitBtn">Confirm</b-button>
        
      </div>

      <BottomMenu :menu="stage"></BottomMenu>
    </div>
  </div>
</template>
  
<script>
import api from "@/apis/api"
import { mapGetters, mapMutations } from 'vuex'
import TopNav from '@/components/TopNav.vue'
import BottomMenu from '@/components/BottomMenu.vue'

export default {
  name: 'RequestConfirmationView',
  components: {
    TopNav,
    BottomMenu
  },

  data() {
    return {
      stage: "Home",
      back: "/home",
      quoteDetails: null,
      submitBtn: false
    }
  },

  computed: {
    ...mapGetters(["getQuoteDetails"])
  },

  created() {
    if(this.getQuoteDetails != null) {
      this.quoteDetails = this.getQuoteDetails
    }    
    else {
      this.navigate("/home")
    }
  },

  methods: {
    ...mapMutations(["setAdvanceDetails"]),

    submit() {
      let that = this

      let data = {}
      data.reference = this.quoteDetails.reference

      this.submitBtn = true

      api.advance(data) 
        .then((response) => {
          that.submitBtn = false
          that.setAdvanceDetails(response.data)

          that.navigate("/request-successful")
        })
        .catch((error) => {
          that.submitBtn = false
          that.showError(error)
        })
    }
  }
}
</script>
  