<template>
  <div class="custom-mobile-view">
    <div class="custom-container bg-white">
      <TopNav :page="stage" :signOutIcon="true"></TopNav>

      <div class="p-4 custom-mb-20 custom-scroll-container">
        <template v-if="customerDetails === null">
          <div class="d-flex justify-content-center p-4">
            <b-spinner label="Loading..."></b-spinner>
          </div>
        </template>

        <template v-else>
          <div>
            <h5 class="custom-text-orange text-center fw-bold">Hello {{ customerDetails.customerName }}!</h5>
          </div>

          <div class="mt-4">
            <p class="custom-text-blue text-center fw-bold">Welcome back to Jilipe Sasa services.</p>
          </div>

          <div class="custom-green-card mt-4 p-4 custom-dotted-border">
            <p class="text-white text-center">Here is the amount available to you</p>
            <h2 class="text-white text-center">KES {{ customerDetails.amount.toLocaleString() }}</h2>
            <p class="text-white text-center custom-light-text">Total earned amount: KES {{
              customerDetails.earnedAmount.toLocaleString() }} </p>

            <div class="d-flex justify-content-center mt-4">
              <b-button class="custom-white-button px-4" @click="requestLoan()">Request
                Advance</b-button>
            </div>
          </div>

          <div class="my-4">
            <div v-if="customerDetails != null">
              <template v-if="customerDetails.transactions.length">
                <div class="d-flex custom-pointer" v-if="!viewTransactions" @click="togglePreviousTransactions()">
                  <font-awesome-icon class="custom-text-blue custom-underline p-1" icon="fa-eye" size="sm" />
                  <p class="custom-text-blue custom-underline">View previous transactions</p>
                </div>

                <div class="d-flex custom-pointer" v-else @click="togglePreviousTransactions()">
                  <font-awesome-icon class="custom-text-orange custom-underline p-1" icon="fa-eye-slash" size="sm" />
                  <p class="custom-text-orange custom-underline">Hide previous transactions</p>
                </div>
              </template>

              <template v-else>
                <b-alert variant="warning" show>No previous transactions.</b-alert>
              </template>

              <div v-if="viewTransactions">
                <b-row class="custom-border-bottom-gray py-2" v-for="(transaction, index) in customerDetails.transactions">
                  <div class="d-flex justify-content-between">
                      <div class="custom-stack">
                          <label class="text-dark fw-bold">{{ transaction.transactionType }}</label>
                          <small class="text-muted">{{ new Date(transaction.transactionDate).toLocaleDateString() }}</small>
                      </div> 
                      
                      <div>
                          <label class="text-muted">KES {{ transaction.amount.toLocaleString() }}</label>
                      </div>
                  </div>   
                </b-row>
              </div>
            </div>
          </div>

          <b-modal modal-class="custom-modal" hide-footer centered v-model="requestModal">
            <template #modal-title>
              Request drawdown
            </template>

            <div>
              <div>
                <b-alert class="text-center" variant="primary" show>Good News!
                  <span class="fw-bold">KES {{ customerDetails.amount.toLocaleString() }}</span>
                  is available for you to draw down today.</b-alert>
              </div>

              <div class="mt-4">
                <p class="text-center mb-2">Request Amount</p>
                <b-form-input placeholder="Enter request amount" type="number" v-model="amountRequested"></b-form-input>
              </div>

              <div class="d-flex justify-content-center mt-4">
                <b-button class="custom-orange-button px-4" @click="submit()" :disabled="submitBtn">Request
                  Advance</b-button>
              </div>
              <hr />
              <p class="text-muted custom-light-text text-center custom-small-text">By proceeding with this loan
                application on our mobile app, you agree to abide by the terms and conditions outlined in our disclaimer."
              </p>
            </div>
          </b-modal>

        </template>
      </div>

      <BottomMenu :menu="stage"></BottomMenu>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import api from '@/apis/api'
import TopNav from '@/components/TopNav.vue'
import BottomMenu from '@/components/BottomMenu.vue'

export default {
  name: 'HomeView',
  components: {
    TopNav,
    BottomMenu
  },

  data() {
    return {
      stage: "Home",
      customerDetails: null,
      amountRequested: null,
      submitBtn: false,
      requestModal: false,
      viewTransactions: false
    }
  },

  created() {
    this.getCustomerDetails()
  },

  methods: {
    ...mapMutations(["setQuoteDetails", "setJilipePercentage"]),

    getCustomerDetails() {
      let that = this

      api.status()
        .then((response) => {
          that.customerDetails = response.data
          that.setJilipePercentage(response.data.drawDownRate)
        })
        .catch((error) => {
          that.showError(error)
        })
    },

    requestLoan() {
      this.requestModal = true
    },

    togglePreviousTransactions() {
      this.viewTransactions = !this.viewTransactions
    },  

    submit() {
      let that = this

      if (this.amountRequested != null) {
        let data = {}
        data.drawDown = parseInt(this.amountRequested)

        this.submitBtn = true

        api.quote(data)
          .then((response) => {
            that.setQuoteDetails(response.data)
            that.submitBtn = false

            that.navigate("/request-confirmation")
          })
          .catch((error) => {
            that.showError(error)
            that.submitBtn = false
          })
      }
      else {
        that.showError("Amount cannot be empty")
      }
    }
  }
}
</script>
