<template>
    <div class="custom-bottom-menu">
       <b-row>
            <b-col @click="navigate('/home')">
                <div :class="[menu === 'Home' ? 'custom-orange-border-bottom' : 'mt-1']"></div>

                <div class="px-2 py-3">
                    <div class="d-flex justify-content-center">
                        <font-awesome-icon :class="[menu === 'Home' ? 'custom-text-orange' : 'text-muted']"  :icon="['fas', 'house']" size="lg"/>
                    </div>

                    <p :class="[menu === 'Home' ? 'custom-text-orange' : 'text-muted', 'text-center', 'mb-0']">Home</p>  
                </div>
            </b-col>

            <b-col @click="navigate('/statement')">
                <div :class="[menu === 'Learn' ? 'custom-orange-border-bottom' : 'mt-1']"></div>

                <div class="px-2 py-3">
                    <div class="d-flex justify-content-center">
                        <font-awesome-icon :class="[menu === 'Learn' ? 'custom-text-orange' : 'text-muted']" :icon="['fas', 'book-open']" size="lg"/>                    
                    </div>

                    <p :class="[menu === 'Learn' ? 'custom-text-orange' : 'text-muted', 'text-center', 'mb-0']">Learn</p>    
                </div>
                    
            </b-col>

            <b-col @click="navigate('/statement')">
                <div :class="[menu === 'Statements' ? 'custom-orange-border-bottom' : 'mt-1']"></div>

                <div class="px-2 py-3">
                    <div class="d-flex justify-content-center">
                        <font-awesome-icon :class="[menu === 'Statements' ? 'custom-text-orange' : 'text-muted']" :icon="['fas', 'scroll']" size="lg"/>
                    </div>
                    
                    <p :class="[menu === 'Statements' ? 'custom-text-orange' : 'text-muted', 'text-center', 'mb-0']">Statements</p>    
                </div>
                
            </b-col>

            <b-col @click="navigate('/profile')">
                <div :class="[menu === 'Profile' ? 'custom-orange-border-bottom' : 'mt-1']"></div>

                <div class="px-2 py-3">
                    <div class="d-flex justify-content-center">
                        <font-awesome-icon :class="[menu === 'Profile' ? 'custom-text-orange' : 'text-muted']" :icon="['fa', 'circle-user']" size="lg"/>
                    </div>
                    
                    <p :class="[menu === 'Profile' ? 'custom-text-orange' : 'text-muted', 'text-center', 'mb-0']">Profile</p> 
                </div>
                   
            </b-col>
       </b-row>
    </div>
</template>

<script>
export default {
    name: 'TopNav',
    components: {
        //
    },

    props: {
        menu: null,
    },

    created() {  
        //
    },    

    data() {
        return {
            //
        }
    },

    methods: {

    }
}
</script>