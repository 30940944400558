<template>
    <div class="custom-mobile-view">
      <div class="custom-container bg-white">
        <TopNav :page="stage" :signOutIcon="true"></TopNav>
  
        <div class="p-4 custom-mb-20 custom-scroll-container">
          <template v-if="statement === null">
            <div class="d-flex justify-content-center p-4">
              <b-spinner label="Loading..."></b-spinner>
            </div>
          </template>
  
          <template v-else>
            <div>
              <h5 class="custom-text-orange text-center fw-bold">Jilipe Sasa transaction history</h5>
            </div>
  
            <div class="my-4">
              <div v-if="statement.length">    
                <b-row class="custom-border-bottom-gray py-2" v-for="(transaction, index) in statement">
                    <div class="d-flex justify-content-between">
                        <div class="custom-stack">
                            <label class="text-dark fw-bold">{{ transaction.transactionType }}</label>
                            <small class="text-muted">{{ new Date(transaction.dateTimeAdded).toLocaleDateString() }}</small>
                        </div> 
                        
                        <div>
                            <label class="text-muted">KES {{ transaction.issue.toLocaleString() }}</label>
                        </div>
                    </div>                   
                  </b-row>
              </div>
              <div v-else>
                <b-alert variant="warning" show>No previous transactions.</b-alert>
            </div>
            </div>  
          </template>
        </div>
  
        <BottomMenu :menu="stage"></BottomMenu>
      </div>
    </div>
  </template>
  
  <script>
  import { mapMutations } from 'vuex'
  import api from '@/apis/api'
  import TopNav from '@/components/TopNav.vue'
  import BottomMenu from '@/components/BottomMenu.vue'
  
  export default {
    name: 'HomeView',
    components: {
      TopNav,
      BottomMenu
    },
  
    data() {
      return {
        stage: "Statements",
        statement: null,
      }
    },
  
    created() {
      this.getCustomerTransactions()
    },
  
    methods: {
        getCustomerTransactions() {
            let that = this

            api.myTransactions()
                .then((response) => {
                    that.statement = response.data
                })
                .catch((error) => {
                    that.showError(error)
                })
        },
  }
}
  </script>
  