import apim from './apim'
import keycloakApim from './keycloakApim'

export default {
    signin(data) {
        return keycloakApim().post('/realms/Sipranda/protocol/openid-connect/token', data)
    },

    status() {
        return apim().get('/status')
    },

    quote(data) {
        return apim().post("/advance/quote", data)
    },

    advance(data) {
        return apim().post("/advance", data)
    },

    resetPin(data) {
        return apim().post("/v1/employees/reset", data)
    },

    setPin(data) {
        return apim().post("/v1/employees/reset", data)
    },

    info() {
        return apim().get("/v1/employees/info")
    },

    verifyPhone(phoneNumber) {
        return apim().get("/v1/employees/search?phoneNumber=" + phoneNumber)
    },

    questions() {
        return apim().get("/v1/questions")
    },

    myQuestions(data) {
        return apim().post("/v1/questions/employee", data)
    },

    saveQuestion(data) {
        return apim().post("/v1/answers", data)
    },

    createLead(data) {
        return apim().post("/v1/leads", data)
    },

    myTransactions() {
        return apim().get("/v1/transactions?sortD=-1")
    }    
}

