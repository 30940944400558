<template>
    <div class="custom-mobile-view">
        <div class="custom-container bg-white">
            <TopNav :path="back"></TopNav>

            <div class="custom-mt-12">
                <h5 class="text-muted text-center fw-bold">Welcome to Jilipe Sasa</h5>
            </div>
            

            <div class="custom-mt-12">
                <div class="px-4">
                    <b-alert class="text-center" variant="success" show>
                        Kindly verify your mobile device for the sent OTP.
                    </b-alert>
                </div>

                <div>
                    <p class="text-muted text-center mb-0">Security Questions</p>
                    <div class="d-flex justify-content-center">
                        <b-form-select  class="custom-input-border-bottom text-center mt-2" v-model="selectedQuestion" 
                            :options="questions" text-field="question" value-field="questionId"></b-form-select>
                    </div>
                </div>

                <div>
                    <p class="text-muted text-center mb-0 mt-4">Answer</p>
                    <b-form-input placeholder="Enter answer" class="custom-input-border-bottom" type="text"
                            v-model="answer"></b-form-input>
                </div>


                <div>
                    <p class="text-muted text-center mb-0 mt-4">OTP</p>
                    <div class="d-flex justify-content-center">
                        <b-form-input placeholder="Enter OTP" class="custom-input-border-bottom" type="number"
                            v-model="otp"></b-form-input>
                    </div>
                </div>

                <div>
                    <p class="text-muted text-center mb-0 mt-4">Enter New PIN</p>
                    <b-form-input placeholder="Enter PIN" class="custom-input-border-bottom" type="number"
                            v-model="pin"></b-form-input>
                </div>


                <div class="custom-mt-12 d-flex justify-content-center">
                    <b-button class="custom-orange-button" @click="submitChangePin()" :disabled="submitBtn">Submit</b-button>
                </div>

                <div class="custom-mt-8 d-flex justify-content-center">
                    <img src="@/assets/img/logo.png" width="150px" />
                </div>

                <div class="py-4 d-flex justify-content-center">
                    <small class="text-muted text-center">Powered by Sipranda Capital Ltd.</small>
                </div>
            </div>
        </div>
    </div>
</template>
    
<script>
import { mapGetters, mapMutations } from 'vuex'
import api from '@/apis/api'
import TopNav from '@/components/TopNav.vue'
import BottomMenu from '@/components/BottomMenu.vue'

export default {
    name: 'LoginPin',
    components: {
        TopNav,
        BottomMenu
    },

    data() {
        return {
            back: "/login/step-one",
            submitBtn: false,
            otp: null,
            pin: null,
            questions: null,
            selectedQuestion: null,
            answer: null,
        }
    },

    computed: {
        ...mapGetters(["getPhoneNumber", "getPayrollNumber", "getNationalIdNumber"])
    },

    mounted() {
        if (this.getPhoneNumber == null || this.getPayrollNumber == null || this.getNationalIdNumber == null) {
            this.navigate("/login/step-one")
            this.showError("Details could not be found!")
        }

        this.fetchQuestions()
    },

    methods: {
        ...mapMutations([
            "setToken"
        ]),

        submitChangePin() {
            let that = this

            if(this.otp != null && this.pin != null) {
                let data = {}

                data.phoneNumber = this.getPhoneNumber
                data.payrollNumber = this.getPayrollNumber
                data.nationalIdNumber = this.getNationalIdNumber
                data.otp = this.otp
                data.pin = this.pin
                data.questionId = this.selectedQuestion
                data.answer = this.answer

                this.submitBtn = true

                api.setPin(data)
                    .then((response) => {
                        that.showSuccess("Your PIN has been schanged successfully!")
                        that.navigate("/login/step-two")
                        that.submitBtn = false
                    })
                    .catch((error) => {
                        that.showError(error)
                        that.submitBtn = false
                    })

            }
            else {
                that.showError("Some details are missing! Please review and try again.")
            }            
        },

        fetchQuestions() {
            let that = this

            let data = {}

            data.phoneNumber = this.getPhoneNumber
            data.payrollNumber = this.getPayrollNumber
            data.nationalIdNumber = this.getNationalIdNumber

            api.myQuestions(data)
                .then((response) => {
                    that.questions = response.data
                })
                .catch((error) => {
                    that.showError(error)
                })
        },
    }
}
</script>
    