<template>
    <div class="custom-mobile-view">
        <div class="custom-container bg-white">
            <TopNav :path="back"></TopNav>

            <div class="px-4">
                <div class="custom-mt-12">
                <h5 class="custom-text-orange text-center fw-bold">Setup security question</h5>
                <p class="text-muted text-center">
                    Welcome to <span class="fw-bold">Jilipe Sasa.</span>
                    Before you can login to your account, please setup a security question.
                </p>
            </div>            

            <div class="custom-mt-12">
                <div>
                    <p class="text-muted text-center mb-0">Security Questions</p>
                    <div class="d-flex justify-content-center">
                        <b-form-select  class="custom-input-border-bottom text-center mt-2" v-model="selectedQuestion" 
                            :options="questions" text-field="question" value-field="questionId"></b-form-select>
                    </div>
                </div>

                <div>
                    <p class="text-muted text-center mb-0 mt-4">Answer</p>
                    <b-form-input placeholder="Enter answer" class="custom-input-border-bottom" type="text"
                            v-model="answer"></b-form-input>
                </div>


                <div class="custom-mt-12 d-flex justify-content-center">
                    <b-button class="custom-orange-button" @click="saveSecurityQuestion()" :disabled="submitBtn">Submit</b-button>
                </div>

                <div class="custom-mt-8 d-flex justify-content-center">
                    <img src="@/assets/img/logo.png" width="150px" />
                </div>

                <div class="mt-4 d-flex justify-content-center">
                    <small class="text-muted text-center">Powered by Sipranda Capital Ltd.</small>
                </div>
            </div>
            </div>
            
        </div>
    </div>
</template>
    
<script>
import { mapGetters, mapMutations } from 'vuex'
import api from '@/apis/api'
import TopNav from '@/components/TopNav.vue'
import BottomMenu from '@/components/BottomMenu.vue'

export default {
    name: 'LoginPin',
    components: {
        TopNav,
        BottomMenu
    },

    data() {
        return {
            questions: null,
            selectedQuestion: null,
            back: "/login/step-one",
            submitBtn: false,
            answer: null,
            pin: null
        }
    },

    computed: {
        ...mapGetters(["getPhoneNumber", "getSecurityQuestion", "getResetPin"])
    },

    mounted() {
        if(this.getSecurityQuestion == null || !this.getSecurityQuestion) {
            this.navigate(this.back)
        }
        else {
            this.fetchQuestions()
        }
    },

    methods: {
        fetchQuestions() {
            let that = this

            api.questions()
                .then((response) => {
                    that.questions = response.data
                })
                .catch((error) => {
                    that.showError(error)
                })
        },

        saveSecurityQuestion() {
            let that = this

            let data = {}

            data.questionId = this.selectedQuestion
            data.answer = this.answer

            this.submitBtn = true

            api.saveQuestion(data)
                .then((response) => {
                    that.submitBtn = false      

                    if(that.getResetPin) {
                        that.navigate("/reset-pin")
                    }
                    else {
                        that.navigate("/home")
                    }
                    
                    that.showSuccess("Question saved successfully!")
                })
                .catch((error) => {
                    this.submitBtn = false
                    that.showError(error)
                })
        }
    }
}
</script>