<template>
    <div class="custom-mobile-view">
        <div class="custom-splash-bg">
            <Ellipsis></Ellipsis>
        </div>
    </div>
</template>

<script>
    import Ellipsis from '@/components/EllipsisLoader.vue'

    export default {
        name: 'SplashView',

        components: {
            Ellipsis,
        },
        
        data() {
            return {

            }
        },

        created() {
            setTimeout(() => {
                this.navigate("/login/step-one")
            }, 4000)
        },

        methods: {
            //
        }
    }
</script>