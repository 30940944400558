<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'APP',
  components: {
    //
  },

  data() {
    return {
      //
    }
  },

  created() {
    //
  },

  methods: {
    //
  }
}
</script>

<style>
  @import '@/assets/css/style.css';
  @import '@/assets/css/responsive.css';
</style>
