import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import { BootstrapVue } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import { faArrowLeft, faArrowRightFromBracket, faBookOpen, faCircleUser, faEye, faEyeSlash, faHouse, faPhone, faScroll } from '@fortawesome/free-solid-svg-icons'  
import {  } from '@fortawesome/free-brands-svg-icons'

import Toasted from 'vue-toasted'
import toast from '@/mixins/toast'

import navigator from '@/mixins/navigate'

Vue.use(BootstrapVue)
Vue.use(Toasted)
Vue.mixin(toast)

Vue.mixin(navigator)

library.add( faArrowLeft, faPhone, faHouse, faBookOpen, faScroll, faCircleUser, faArrowRightFromBracket, faEye, faEyeSlash )
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
