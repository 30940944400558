<template>
    <div class="custom-top-nav">
        <div class="d-flex justify-content-between px-2">
            <div>
                <template v-if="path != null">
                    <font-awesome-icon class="custom-red-icon text-white" :icon="['fa', 'arrow-left']" @click="navigate(path)"/>   
                </template>
                <template v-else-if="page != null">
                    <label class="text-white">{{ page }}</label>
                </template>
            </div>

            <div>
                <template v-if="signOutIcon != null">
                    <font-awesome-icon class="custom-red-icon text-white" :icon="['fas', 'arrow-right-from-bracket']" @click="logout()"/>
                </template>
            </div>                      
        </div>       
    </div>
</template>

<script>
export default {
    name: 'TopNav',

    props: {
        page: null,
        path: null,
        signOutIcon: null
    },

    data() {
        return {
            //
        }
    },

    mounted() {
        //
    },

    methods: {
        logout() {
            this.navigate("/login/step-one")
            this.showSuccess("Succesfully logged out!")
        }
    }
}
</script>