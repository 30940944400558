import axios from 'axios'
import { config } from '@/util/config.js'
import router from '@/router'; 

let APIM = function() {
  let baseAPI  = axios.create({
    baseURL: config.backendURL,
    headers: {
      'Content-Type': 'application/json',
    }
  })

  const token = localStorage.getItem('token')

  if (token != undefined && token != null) {
    baseAPI.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  }
  else {
    //router.push("/login/step-one") 
  }

  baseAPI.interceptors.response.use(
    response => response,
    error => {
      if (error.response.status === 401 || error.response.status === 403) {       
        router.push("/login/step-one") 
      }
      return Promise.reject(error)
    }
  )

  return baseAPI
}

export default APIM; 